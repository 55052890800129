import { AxiosResponse } from 'axios';
import { backendClient as client } from './clients';
import { RequestMethod } from './types';

export const IMPACT_ADMINISTRATOR_ROLE = 'administrator';
export const IMPACT_NDC_ADMINISTRATOR_ROLE = 'ndc-administrator';

interface KeycloakTokenResponse {
    access_token: string;
    expires_in: number;
    'not-before-policy': number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
}

export const impersonateUser: RequestMethod<
    { subjectToken: string; requestedSubject: string },
    KeycloakTokenResponse
> = ({ subjectToken, requestedSubject, config }) =>
    client
        .post(
            `${import.meta.env.VITE_KEYCLOAK_URL}/realms/cern/protocol/openid-connect/token`,
            {
                client_id: import.meta.env.VITE_KEYCLOAK_CLIENTID,
                grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
                subject_token: subjectToken,
                requested_token_type: 'urn:ietf:params:oauth:token-type:refresh_token',
                audience: import.meta.env.VITE_KEYCLOAK_CLIENTID,
                requested_subject: requestedSubject,
                scope: 'openid profile email',
            },
            { ...config, headers: { 'content-type': 'application/x-www-form-urlencoded' } }
        )
        .then((response: AxiosResponse) => response.data);
