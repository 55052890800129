/* eslint-disable react/destructuring-assignment*/
import { Paper, Stack, Switch, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    allowAnyLocations,
    allowSelectableLocations,
    createNdc,
    getNdcPdf,
    linkNdcAttachments,
    unlinkNdcAttachments,
    updateNdc,
    updateNdcAttachment,
} from '../../api/documents/noteDeCoupures';
import { DocumentType, NdcActionDTO, NdcContextReadDTO, NdcReadDTO, NdcStatusCode } from '../../api/dto';
import { IMPACT_NDC_ADMINISTRATOR_ROLE } from '../../api/keycloak';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { LocalizedDocumentPdfButton } from '../../components/documents/LocalizedDocumentPdfButton';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { InactiveActivityWarning } from '../../components/InactiveActivityWarning';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useRole } from '../../hooks/useRole';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import {
    makeDefaultValuesFromClonedNdc,
    makeDefaultValuesFromLinkedActivity,
    makeDefaultValuesFromNdc,
    mapNdcToUpdateDTO,
} from '../../lib/records/documents/noteDeCoupure';
import { AttachmentsAction } from '../../pages/documents/ndc/AttachmentsAction';
import { NdcContentHeader } from '../../pages/documents/ndc/NdcContentHeader';
import { NdcPageData } from '../../pages/documents/ndc/NdcPageData';
import { NdcPageVariant } from '../../pages/documents/ndc/NoteDeCoupurePage';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { useRules } from '../rule/useRules';
import { uiSchemaNoteDeCoupureFor } from '../schemas/documents/noteDeCoupure';

const NDC_NOTIFIED_STATUS_CODES: (NdcStatusCode | null)[] = [
    NdcStatusCode.DISTRIBUTED,
    NdcStatusCode.FINISHED,
    NdcStatusCode.WAITING_NOTIFY_FINISH,
];

interface NoteDeCoupureFormProps {
    data: NdcPageData;
    rules: PreciseRulesDTO<NdcContextReadDTO>;
    refreshNoteDeCoupureData: () => void;
}

export const NoteDeCoupureForm: React.FC<NoteDeCoupureFormProps> = ({
    data: pageData,
    rules,
    refreshNoteDeCoupureData,
}) => {
    const { withDispatchTaskEvent } = useTaskContext();
    const { variant } = pageData;
    const intl = useIntl();
    const navigate = useNavigate();
    const isNdcAdmin = useRole(IMPACT_NDC_ADMINISTRATOR_ROLE);
    const { currentUser, availableLanguages } = useApplicationSettings();
    const defaultValues = useMemo(
        () =>
            variant === NdcPageVariant.CREATE
                ? makeDefaultValuesFromLinkedActivity(pageData.activityData, currentUser, availableLanguages)
                : variant === NdcPageVariant.CLONE
                ? makeDefaultValuesFromClonedNdc(pageData.noteDeCoupureData, pageData.activityData)
                : makeDefaultValuesFromNdc(pageData.noteDeCoupureData),
        [variant, pageData, currentUser, availableLanguages]
    );

    const form = useForm({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultValues,
            id: variant === NdcPageVariant.READ ? pageData.noteDeCoupureData.id : undefined,
        },
    });
    const { handleSubmit, clearErrors } = form;

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.NDC);

    const tasks = useTasks(
        pageData.variant === NdcPageVariant.READ
            ? {
                  documentTypes: [DocumentType.NDC],
                  documentId: pageData.noteDeCoupureData.id,
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData: refreshNoteDeCoupureData,
              }
            : undefined
    );
    const context: NdcContextReadDTO | undefined = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      action: variant === NdcPageVariant.READ ? null : NdcActionDTO.CREATE,
                      task: tasks.currentTask?.type?.type ?? null,
                      // isSelectableAnyLocation doesn't matter the value
                  }
                : undefined,
        [rules.context, tasks.currentTask]
    );
    const attributes = useRules(form, rules, defaultValues, context);
    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;

    const isSavable = useIsSavableFromRules(attributes);

    const { mutateAsync: saveNdc, isLoading: isSaveLoading } = useMutation(
        ({ data: formData }: { data: any }) => {
            if (variant === NdcPageVariant.READ) {
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateNdc({
                    id: pageData.noteDeCoupureData.id,
                    taskId: tasks.currentTask.id,
                    data: mapNdcToUpdateDTO(formData, false),
                });
            } else if (variant === NdcPageVariant.CLONE || variant === NdcPageVariant.CREATE) {
                return createNdc({ data: mapNdcToUpdateDTO(formData, true) }).then(artificialDelay);
            } else return new Promise((resolve) => resolve({}));
        },
        {
            onSuccess: (result) => {
                if (variant === NdcPageVariant.READ) {
                    refreshNoteDeCoupureData();
                } else if (variant === NdcPageVariant.CLONE || variant === NdcPageVariant.CREATE) {
                    toast.success(
                        intl.formatMessage({ id: 'ndc.success.create' }),
                        { autoClose: 20000 } // Long delay to make sure they read it
                    );
                    navigate(
                        getRoute({
                            path: ROUTES.noteDeCoupure.view,
                            params: { id: String((result as NdcReadDTO).id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: (response: AxiosResponse) => handleFormSaveError(response),
        }
    );

    const { mutate: mutateLocationSelectionPermission, isLoading: isLoadingLocationSelectionPermission } = useMutation(
        ({ id, isAllowAny }: { id: number; isAllowAny: boolean }) => {
            if (isAllowAny) {
                return allowAnyLocations({ id });
            } else {
                return allowSelectableLocations({ id });
            }
        },
        {
            onSuccess: () => refreshNoteDeCoupureData(),
            onError: () => {
                toast.warning(intl.formatMessage({ id: 'common.error' }));
            },
        }
    );

    const { isDirty: formIsDirty } = form.formState;
    const isDirty = formIsDirty || variant !== NdcPageVariant.READ; // Create/clone form is always dirty

    const isLoading = isSaveLoading || tasks.disabled || isLoadingLocationSelectionPermission;
    // Unfortunately due to a limitation in the current implementation, we cannot enable this feature on create/clone
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: variant === NdcPageVariant.READ && (isDirty || isLoading),
    });
    const refreshNoteDeCoupureDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshNoteDeCoupureData);

    const isDone =
        variant === NdcPageVariant.READ && NDC_NOTIFIED_STATUS_CODES.includes(pageData.noteDeCoupureData.status.code);
    const allowAnyLocation = variant === NdcPageVariant.READ && pageData.noteDeCoupureData.allowAnyLocation;
    const uiSchemaNoteDeCoupure = useMemo(() => uiSchemaNoteDeCoupureFor({ isDone, allowAnyLocation }, intl), [isDone]);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <NdcContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === NdcPageVariant.READ
                                ? refreshNoteDeCoupureDataWithUnsavedChangesPrompt
                                : undefined
                        }
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.SAVE}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveNdc({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({ id: 'document.form.disabledNoChange' })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                                {pageData.variant !== NdcPageVariant.READ && pageData.activityData !== null ? (
                                    <>
                                        {pageData.variant === NdcPageVariant.CREATE &&
                                            !pageData.activityData.isActive && <InactiveActivityWarning />}
                                    </>
                                ) : null}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1}>
                                {pageData.variant === NdcPageVariant.READ && !!pageData.noteDeCoupureData.pdf && (
                                    <LocalizedDocumentPdfButton
                                        queryKey={['ndc', pageData.noteDeCoupureData.id]}
                                        fetchPdf={() =>
                                            getNdcPdf({
                                                id: pageData.noteDeCoupureData.id,
                                            })
                                        }
                                        isPdfPreview={pageData.noteDeCoupureData.pdf.isPdfPreview}
                                        canGenerateInBothLanguages={false}
                                        disabled={isDirty}
                                        disablingReason={intl.formatMessage({ id: 'document.form.disabledUnsaved' })}
                                    />
                                )}
                                {variant === NdcPageVariant.READ && (
                                    <AttachmentsAction
                                        attachments={pageData.noteDeCoupureData.attachments}
                                        linkAttachments={(attachments) =>
                                            linkNdcAttachments({
                                                id: pageData.noteDeCoupureData.id,
                                                taskId: tasks.currentTask?.id!,
                                                ...attachments,
                                            })
                                        }
                                        unlinkAttachments={(attachments) =>
                                            unlinkNdcAttachments({
                                                id: pageData.noteDeCoupureData.id,
                                                taskId: tasks.currentTask?.id!,
                                                ...attachments,
                                            })
                                        }
                                        updateAttachment={(data) =>
                                            updateNdcAttachment({
                                                id: pageData.noteDeCoupureData.id,
                                                taskId: tasks.currentTask?.id!,
                                                ...data,
                                            })
                                        }
                                        readOnly={!isSavable}
                                    />
                                )}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <ValidationErrorsPanel schema={uiSchemaNoteDeCoupure} />
                        <LinkedDocumentsPanel
                            type={EntityType.NoteDeCoupure}
                            ndc={{ activity: pageData.activityData }}
                        />
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator rootElement={uiSchemaNoteDeCoupure} form={form} disabled={isLoading} />
                        </FieldsAttributesContextProvider>
                        {variant === NdcPageVariant.READ && (
                            <WorkflowHistory
                                documentType={DocumentType.NDC}
                                documentId={pageData.noteDeCoupureData.id}
                            />
                        )}
                        {variant === NdcPageVariant.READ && isNdcAdmin && (
                            <Paper sx={{ backgroundColor: '#fff6b0', marginTop: 2, padding: 2 }}>
                                <Typography>
                                    <FormattedMessage id="ndc.adminOptions" />
                                </Typography>
                                <Stack direction="row" alignItems="center">
                                    <Switch
                                        disabled={isLoading}
                                        defaultChecked={allowAnyLocation}
                                        onChange={(event) =>
                                            mutateLocationSelectionPermission({
                                                id: pageData.noteDeCoupureData.id,
                                                isAllowAny: event.target.checked,
                                            })
                                        }
                                    />
                                    <Typography>
                                        <FormattedMessage id="ndc.anyLocation" />
                                    </Typography>
                                </Stack>
                            </Paper>
                        )}
                        <ImpactHelmet
                            documentType={DocumentType.NDC}
                            documentId={variant === NdcPageVariant.READ ? pageData.noteDeCoupureData.id : undefined}
                            title={intl.formatMessage(
                                {
                                    id: (
                                        {
                                            [NdcPageVariant.CREATE]: 'title.ndcCreate',
                                            [NdcPageVariant.CLONE]: 'title.ndcClone',
                                            [NdcPageVariant.READ]: 'title.ndcView',
                                        } as const
                                    )[variant],
                                },
                                {
                                    ndcNumber:
                                        variant === NdcPageVariant.READ || variant === NdcPageVariant.CLONE
                                            ? pageData.noteDeCoupureData.ndcNumber
                                            : undefined,
                                }
                            )}
                        />
                    </>
                }
                Drawer={
                    variant === NdcPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshNoteDeCoupureData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};
